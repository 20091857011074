var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "csv-import", fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "text-right", attrs: { md: "4", "offset-md": "8" } },
            [
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "mr-3",
                                  attrs: { color: "grey" },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v(" CSV Templates ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.csvTemplates, function (item, index) {
                      return _c(
                        "v-list-item",
                        { key: index },
                        [
                          _c("v-list-item-title", [
                            _c(
                              "a",
                              {
                                staticStyle: {
                                  "text-decoration": "none",
                                  color: "black",
                                },
                                attrs: { href: item.link },
                              },
                              [_vm._v(_vm._s(item.name))]
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-0",
                  attrs: { color: "blue" },
                  on: {
                    click: function ($event) {
                      return _vm.openCsvImport()
                    },
                  },
                },
                [_vm._v(" Upload CSV ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          "page-count": _vm.numberOfPages,
          headers: _vm.headers,
          items: _vm.csvImportJobs,
          options: _vm.options,
          "server-items-length": _vm.totalCsvImportJobs,
          loading: _vm.loading,
        },
        on: {
          "update:options": function ($event) {
            _vm.options = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item.status",
            fn: function ({ item }) {
              return [
                item.status === 1
                  ? _c("v-progress-circular", {
                      attrs: {
                        indeterminate: "",
                        size: "20",
                        color: "grey",
                        inline: "",
                      },
                    })
                  : _c("v-badge", {
                      attrs: {
                        "offset-x": "5",
                        color: _vm.statusBadgeColor(item.status),
                        dot: "",
                      },
                    }),
              ]
            },
          },
          {
            key: "item.created_at",
            fn: function ({ item }) {
              return [
                _vm._v(" " + _vm._s(_vm.formatDate(item.created_at)) + " "),
              ]
            },
          },
          {
            key: "item.updated_at",
            fn: function ({ item }) {
              return [
                _vm._v(" " + _vm._s(_vm.formatDate(item.updated_at)) + " "),
              ]
            },
          },
          {
            key: "item.processed_at",
            fn: function ({ item }) {
              return [
                _vm._v(" " + _vm._s(_vm.formatDate(item.processed_at)) + " "),
              ]
            },
          },
          {
            key: "item.errored_at",
            fn: function ({ item }) {
              return [
                _vm._v(" " + _vm._s(_vm.formatDate(item.errored_at)) + " "),
              ]
            },
          },
          {
            key: "item.error_message",
            fn: function (props) {
              return [
                props.item.error_message
                  ? _c(
                      "v-edit-dialog",
                      {
                        attrs: {
                          "return-value": props.item.error_message,
                          width: "250px",
                        },
                        on: {
                          "update:returnValue": function ($event) {
                            return _vm.$set(props.item, "error_message", $event)
                          },
                          "update:return-value": function ($event) {
                            return _vm.$set(props.item, "error_message", $event)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pa-3",
                                      staticStyle: { "max-width": "250px" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "1" } },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-alert-octagon"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c("v-col", [
                                            _c("h4", [_vm._v("Error")]),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(props.item.error_message) +
                                          " "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        props.item.error_message
                          ? _c("v-icon", [_vm._v(" mdi-alert-octagon ")])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.file",
            fn: function ({ item }) {
              return [
                _c("v-col", [
                  _c(
                    "a",
                    { attrs: { href: item.file } },
                    [_c("v-icon", [_vm._v("mdi-file-excel-outline")])],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("csv-import-form"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }