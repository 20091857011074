var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "csv-import-form" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.csvImportDialog,
            callback: function ($$v) {
              _vm.csvImportDialog = $$v
            },
            expression: "csvImportDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticStyle: { "background-color": "#1e88e5" } },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("span", { staticClass: "text-h5 white--text" }, [
                          _vm._v("CSV Upload"),
                        ]),
                      ]),
                      _c(
                        "v-col",
                        [
                          _c("v-icon", { attrs: { color: "white" } }, [
                            _vm._v(" mdi-file-upload-outline mdi-48px "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.uploadProgress > 0
                ? _c("v-progress-linear", {
                    model: {
                      value: _vm.uploadProgress,
                      callback: function ($$v) {
                        _vm.uploadProgress = $$v
                      },
                      expression: "uploadProgress",
                    },
                  })
                : _vm._e(),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.formValid,
                        callback: function ($$v) {
                          _vm.formValid = $$v
                        },
                        expression: "formValid",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("customer-auto-complete")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  label: "Job Type",
                                  items: _vm.customerImportJobTypes,
                                  "item-text": "name",
                                  "item-value": "id",
                                  rules: [_vm.rules.required],
                                },
                                model: {
                                  value: _vm.jobType,
                                  callback: function ($$v) {
                                    _vm.jobType = $$v
                                  },
                                  expression: "jobType",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-row", [_vm._v("Overwrite Fields")]),
                      _c(
                        "v-row",
                        [
                          _c("v-checkbox", {
                            staticClass: "pr-2",
                            attrs: { label: "All" },
                            model: {
                              value: _vm.overwriteAll,
                              callback: function ($$v) {
                                _vm.overwriteAll = $$v
                              },
                              expression: "overwriteAll",
                            },
                          }),
                          _c("v-checkbox", {
                            staticClass: "pr-2",
                            attrs: { label: "Shipment Notes" },
                            model: {
                              value: _vm.overwriteShipmentNotes,
                              callback: function ($$v) {
                                _vm.overwriteShipmentNotes = $$v
                              },
                              expression: "overwriteShipmentNotes",
                            },
                          }),
                          _c("v-checkbox", {
                            staticClass: "pr-2",
                            attrs: { label: "Customer Price" },
                            model: {
                              value: _vm.overwriteCustomerPrice,
                              callback: function ($$v) {
                                _vm.overwriteCustomerPrice = $$v
                              },
                              expression: "overwriteCustomerPrice",
                            },
                          }),
                          _c("v-checkbox", {
                            staticClass: "pr-2",
                            attrs: { label: "Carrier Start Buy" },
                            model: {
                              value: _vm.overwriteCarrierStartBuy,
                              callback: function ($$v) {
                                _vm.overwriteCarrierStartBuy = $$v
                              },
                              expression: "overwriteCarrierStartBuy",
                            },
                          }),
                          _c("v-checkbox", {
                            staticClass: "pr-2",
                            attrs: { label: "Carrier Max Buy" },
                            model: {
                              value: _vm.overwriteCarrierMaxBuy,
                              callback: function ($$v) {
                                _vm.overwriteCarrierMaxBuy = $$v
                              },
                              expression: "overwriteCarrierMaxBuy",
                            },
                          }),
                          _c("v-checkbox", {
                            staticClass: "pr-2",
                            attrs: { label: "Carrier Price" },
                            model: {
                              value: _vm.overwriteCarrierPrice,
                              callback: function ($$v) {
                                _vm.overwriteCarrierPrice = $$v
                              },
                              expression: "overwriteCarrierPrice",
                            },
                          }),
                          _c("v-checkbox", {
                            staticClass: "pr-2",
                            attrs: { label: "Weight" },
                            model: {
                              value: _vm.overwriteLoadWeight,
                              callback: function ($$v) {
                                _vm.overwriteLoadWeight = $$v
                              },
                              expression: "overwriteLoadWeight",
                            },
                          }),
                          _c("v-checkbox", {
                            staticClass: "pr-2",
                            attrs: { label: "Height" },
                            model: {
                              value: _vm.overwriteLoadHeight,
                              callback: function ($$v) {
                                _vm.overwriteLoadHeight = $$v
                              },
                              expression: "overwriteLoadHeight",
                            },
                          }),
                          _c("v-checkbox", {
                            staticClass: "pr-2",
                            attrs: { label: "Description" },
                            model: {
                              value: _vm.overwriteDescription,
                              callback: function ($$v) {
                                _vm.overwriteDescription = $$v
                              },
                              expression: "overwriteDescription",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-file-input", {
                            attrs: {
                              "show-size": "",
                              counter: "",
                              hint: "File must be a .csv format",
                              "persistent-hint": "",
                              label: "File input",
                              accept: ".csv",
                              required: "",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.fileInput,
                              callback: function ($$v) {
                                _vm.fileInput = $$v
                              },
                              expression: "fileInput",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", text: "", color: "red" },
                              on: {
                                click: function ($event) {
                                  return _vm.cancelUpload()
                                },
                              },
                            },
                            [_vm._v(" Close ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", text: "", color: "blue" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitUpload()
                                },
                              },
                            },
                            [_vm._v(" Submit ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }